body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #e1e3ea;
  // font-family: 'Saira', sans-serif;
  // background: #000;
  // font-family: "Inter", sans-serif;
  font-family: "Roboto", sans-serif;
}

.dCol {
  flex-direction: column;
}
.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoImg {
  width: 200px;
}
.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  // h2: {
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
  @media (max-width: 367px) {
    font-size: 12px !important;
  }
  // }
}

.logoImg {
}
.MuiSelect-select:focus {
  border-radius: 0;
  background-color: #fff !important;
}

.even-row {
  background: #feefef;
  /* Define your desired background color for even rows */
}

.odd-row {
  background-color: #fff; /* Define your desired background color for odd rows */
}
.MuiButton-contained:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #d83a3a !important;
}
.MuiTableCell-root {
  font-weight: 400 !important;
}
