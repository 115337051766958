body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #e1e3ea;
  font-family: "Roboto", sans-serif;
}

.dCol {
  flex-direction: column;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoImg {
  width: 200px;
}

.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .centerLogoBox {
    font-size: 18px !important;
  }
}
@media (max-width: 367px) {
  .centerLogoBox {
    font-size: 12px !important;
  }
}

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: #fff !important;
}

.even-row {
  background: #feefef;
  /* Define your desired background color for even rows */
}

.odd-row {
  background-color: #fff; /* Define your desired background color for odd rows */
}

.MuiButton-contained:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #d83a3a !important;
}

.MuiTableCell-root {
  font-weight: 400 !important;
}

.bannerBoxShadow {
  position: relative;
}

.scrolling-text-container {
  width: 100%;
  overflow: hidden; /* This hides the overflowing text */
}

.scrolling-text {
  display: inline-block; /* Display as inline block so it only takes as much width as the text */
  white-space: nowrap; /* Prevent text from wrapping */
  animation: scroll-left 10s linear infinite; /* Use the defined animation for scrolling */
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start position: completely off the screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End position: completely off the screen to the left */
  }
}
.message-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.message {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.numberTextField input::-webkit-outer-spin-button,
.numberTextField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #ae7200 0%, #d56a06 100%);
  filter: blur(150px);
  z-index: 1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.blink {
  animation: blink-animation 2s infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.zoomInOut {
  animation: ZoomIn 2s infinite;
}

.mainContainer1 {
  margin-top: 1rem !important;
  padding: 30px 60px;
  border-radius: 10px;
}
.mainContainer1 h3 {
  color: #222;
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .mainContainer1 {
    padding: 30px 30px;
  }
}

label {
  font-family: Arial;
}

.dropzonebox {
  margin-top: 12px;
  cursor: pointer;
}
@media screen and (max-width: 959px) {
  .dropzonebox {
    margin-top: 0px;
  }
}
@media screen and (max-width: 767px) {
  .dropzonebox {
    padding: 30px 9px 20px 9px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox {
    padding: 20px 9px 20px 9px;
  }
}
.dropzonebox .lable {
  text-align: center;
}
.dropzonebox .boxImage {
  position: relative;
  width: 100%;
  background-color: #efefef;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .boxImage span {
  position: absolute;
  top: 40%;
  right: 43%;
  background-color: #efefef;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .zebraCrossButton {
  background-image: linear-gradient(45deg, transparent 49.5%, #fff 49.5%, #fff 50.5%, transparent 50.5%), linear-gradient(135deg, transparent 49.5%, #fff 49.5%, #fff 50.5%, transparent 50.5%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
}
.dropzonebox .columnShow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropzonebox .boxImage1 {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #efefef;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .boxImage1 span {
  position: absolute;
  top: 40%;
  right: 43%;
  background-color: #efefef;
  width: 50px;
  height: 50px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage1 img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 600px) {
  .dropzonebox .boxImage1 img {
    min-width: 200px;
    min-height: 200px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 {
    width: 200px;
    height: 200px;
  }
}
.dropzonebox button {
  width: 184px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.star {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
  background-color: #fff;
  -webkit-clip-path: polygon(50% 0%, 61.8% 20%, 100% 35.4%, 80.9% 61.8%, 90.9% 90.9%, 64.6% 77.3%, 35.4% 77.3%, 9.1% 90.9%, 19.1% 61.8%, 0% 35.4%);
          clip-path: polygon(50% 0%, 61.8% 20%, 100% 35.4%, 80.9% 61.8%, 90.9% 90.9%, 64.6% 77.3%, 35.4% 77.3%, 9.1% 90.9%, 19.1% 61.8%, 0% 35.4%);
}

.star1 {
  animation: rotate 3s infinite linear;
}

.star2 {
  animation: rotate 4s infinite linear;
}

.star3 {
  animation: rotate 5s infinite linear;
}

.star4 {
  animation: rotate 6s infinite linear;
}

.star5 {
  animation: rotate 7s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 1225px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .BetAmountBox {
    text-align: center !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .containerBox {
    padding: 25px !important;
    margin-top: 40px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .redtimerTextBox {
    font-size: 14px !important;
    background: rgba(255, 255, 255, 0.2117647059);
    padding: 5px;
    border-radius: 5px;
  }
  .dragText {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .spinnowImg {
    width: 126px !important;
  }
  .spinnowImg1 {
    width: 113px !important;
  }
  .BetAmountBox {
    margin-left: 15px !important;
    margin-top: -8px !important;
    text-align: center !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }
  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}
@media (max-width: 425px) {
  .spinnowImg {
    width: 153px !important;
    margin-top: 0px !important;
    max-width: 100%;
  }
}/*# sourceMappingURL=main.css.map */