@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .TopbarButton {
    // margin: 6px 0 !important;
  }
}
@media (max-width: 1225px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // margin: 6px 0 !important;
  .BetAmountBox {
    text-align: center !important;
  }
  .wheelSpin11 {
    // margin-top: 20px;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .containerBox {
    padding: 25px !important;
    margin-top: 40px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // .orangeshadeBox {
  //   display: none;
  // }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .redtimerTextBox {
    font-size: 14px !important;
    background: #ffffff36;
    padding: 5px;
    border-radius: 5px;
  }
  .dragText {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .spinnowImg {
    width: 126px !important;
  }
  .spinnowImg1 {
    width: 113px !important;
  }
  .BetAmountBox {
    margin-left: 15px !important;
    margin-top: -8px !important;
    text-align: center !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }

  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }

  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}

@media (max-width: 425px) {
  .spinnowImg {
    width: 153px !important;
    margin-top: 0px !important;
    max-width: 100%;
  }
}
